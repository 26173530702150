import React from 'react';
import { Spin } from 'antd';
import { Link, navigate } from 'gatsby';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from 'react-query';
import moment from 'moment';

import SEO from '../../../components/seo';
import PageHeader from '../../../components/common/page-header';
import UserContent from '../../../components/common/user-content';
import { UserContentLayoutType } from '../../../types/layout';
import { StyledButton } from '../../../components/home/section-styles';
import { FormStatus } from '../../../types/form';
import {
  FormCompletedStatusBlock,
  FormInProgressStatusBlock,
  FormNotStartedStatusBlock,
} from '../../../components/admin/common/blocks';
import { FORM_STATUS_DICTIONARY } from '../../../components/admin/common/constants';
import { getJobDetailsById } from '../../../apis/users/career-center';
import { tenantLabel } from '../../../siteContent';
import { formatDate } from '../../../utils/date';
import theme from '../../../theme';
import { JOB_CAMPAIGN_TYPE_LABELS } from '../../../constants';
import { CampaignType } from '../../../types/models/program-management';

const InternalContainer = styled.div`
  padding: 4px 20px 4px 20px;
`;
const Type = styled.div`
  color: #6e6e6e;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`;
const JobName = styled.div`
  margin-top: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
`;
const CompanyName = styled.div`
  margin-top: 4px;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
`;
const Description = styled.div`
  color: #000000;
  margin-top: 23px;
`;
const CompanyLocation = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
`;
const Deadline = styled.div`
  margin-top: 24px;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: right;

  @media (max-width: ${theme.screenXsMax}) {
    text-align: left;
  }
`;
const Status = styled.div`
  @media (max-width: ${theme.screenXsMax}) {
    margin: 15px 0;
  }
`;
const EndDate = styled.div`
  margin-top: 2px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1125px;
  color: #000000;
`;
const DefaultBottomLine = styled.div`
  display: inline-block;
  border-bottom: 1px solid #dddddd;
  margin-top: 24px;
  width: 100%;
`;
const JobDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${theme.screenXsMax}) {
    flex-direction: column;
  }
`;

const renderStatusTag = (text: string) => {
  let button;
  switch (text) {
    case FormStatus.NotStarted:
      button = (
        <FormNotStartedStatusBlock noMargin>{FORM_STATUS_DICTIONARY[FormStatus.NotStarted]}</FormNotStartedStatusBlock>
      );
      break;
    case FormStatus.InProgress:
      button = (
        <FormInProgressStatusBlock noMargin>{FORM_STATUS_DICTIONARY[FormStatus.InProgress]}</FormInProgressStatusBlock>
      );
      break;
    case FormStatus.Completed:
      button = (
        <FormCompletedStatusBlock noMargin>{FORM_STATUS_DICTIONARY[FormStatus.Completed]}</FormCompletedStatusBlock>
      );
      break;
    default:
      button = <p>--</p>;
      break;
  }
  return button;
};

const JobDetails = ({ stageId }: RouteComponentProps<any>) => {
  const { data: jobDetailsData, isLoading, isFetching } = useQuery(getJobDetailsById.QUERY_KEY, () =>
    getJobDetailsById(stageId)
  );

  const handleClick = () => {
    navigate(`/myhsf/form/${jobDetailsData.stage.id}`);
  };

  const isDisable = () => {
    return jobDetailsData.stage.formStatus === FORM_STATUS_DICTIONARY[FormStatus.Completed];
  };

  const renderProgramDesc = (__html: string) => {
    return <div dangerouslySetInnerHTML={{ __html }}></div>;
  };

  const renderContent = () => {
    if (!jobDetailsData) {
      return null;
    }

    return (
      <>
        <div style={{ backgroundColor: '#FFF', padding: '20px' }}>
          <InternalContainer>
            <JobDetailsHeader>
              <div>
                <Type>{JOB_CAMPAIGN_TYPE_LABELS[jobDetailsData.additionalData.campaignType as CampaignType]}</Type>
                <JobName>{jobDetailsData.name}</JobName>
                <CompanyName>{jobDetailsData.additionalData?.company?.company}</CompanyName>
                <CompanyLocation>
                  {jobDetailsData.additionalData.location ? jobDetailsData.additionalData.location : '-'}
                </CompanyLocation>
                <Deadline>Deadline ({moment(jobDetailsData.stage.endDate).diff(moment(), 'days')} days left)</Deadline>
                <EndDate>
                  {formatDate(jobDetailsData.stage.endDate, 'MMM DD, YYYY h:mm a Z', jobDetailsData.stage.timezone)}
                </EndDate>
              </div>
              <LeftSide>
                <Status> {renderStatusTag(jobDetailsData.stage.formStatus)}</Status>
                <StyledButton type="primary-blue" onClick={handleClick} disabled={isDisable()}>
                  Interest Form
                </StyledButton>
              </LeftSide>
            </JobDetailsHeader>
            <DefaultBottomLine />
            <Description>{renderProgramDesc(jobDetailsData.description ? jobDetailsData.description : '')}</Description>
          </InternalContainer>
        </div>
      </>
    );
  };

  const isReady = !isLoading && !isFetching;

  return (
    <>
      <SEO title="Program Detail" />
      <PageHeader
        title={null}
        noShadow
        backgroundColor={theme.colorsLightestGrey}
        breadcrumb={[
          <Link key="1" to="/myhsf/dashboard">
            {tenantLabel} Dashboard
          </Link>,
          <Link key="2" to="/myhsf/career-center">
            Career Center
          </Link>,
          'Job Details',
        ]}
      />
      <Spin spinning={!isReady}>
        <UserContent type={UserContentLayoutType.DASHBOARD}>{renderContent()}</UserContent>
      </Spin>
    </>
  );
};
export default JobDetails;
