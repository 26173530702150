import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from '@reach/router';
import { Link, navigate } from 'gatsby';
import { Breadcrumb, Select, Row, Col, Spin } from 'antd';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { ImageContainer, Wrapper, StyledContainerTracks, StyledHeaderContainer, LogoContainer } from './styles';

import TracksList from '../../../components/user/hsf-university/sorted-tracks-list';
import { getFilteredTracks, setPreferredCourseLanguages } from '../../../apis/users/hsf-university';
import UniversityLogo from '../../../resources/images/hsf/hsf_Uni-logo.svg';
import { getUser } from '../../../state/selectors/auth';
import { NavigationWrapper } from '../../../components/common/page-header';
import Loader from '../../../components/common/loader';
import { programShortName, tenantLabel } from '../../../siteContent';
import TabNavigation from '../../../components/common/tab-navigation';
import theme from '../../../theme';
import { IFilteredTrackDetails } from '../../../types/models/hsf-university';
import { Modal } from '../../../components';

const { Option } = Select;

type UniversityTracksListProps = {
  path?: string;
};

export const StyledMessage = styled.div`
  color: ${theme.colorsBlack};
  font-weight: bold;
  font-size: 18px;
  margin: 20px 20px;
  text-align: center;
  word-break: break-word;
`;

const StyledLAbel = styled.div`
  color: ${theme.colorsBlack};
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`;

export enum MenuItemsTabIds {
  RECOMMENDED = 'recommended',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
}

function UniversityTracksList(_path: RouteComponentProps<UniversityTracksListProps>) {
  const getTrackTypeFromPath = (path: string): string => {
    let trackType = MenuItemsTabIds.RECOMMENDED;

    if (path.includes(MenuItemsTabIds.IN_PROGRESS.toLowerCase())) {
      trackType = MenuItemsTabIds.IN_PROGRESS;
    } else if (path.includes(MenuItemsTabIds.COMPLETED)) {
      trackType = MenuItemsTabIds.COMPLETED;
    }

    return trackType;
  };

  const userInfo = useSelector(getUser);
  const [userId, setUserId] = useState<string>('');
  const [filteredTracks, setFilteredTracks] = useState<IFilteredTrackDetails[]>([]);
  const [tabType, setTabType] = useState<string>(getTrackTypeFromPath(window.location.pathname));
  const [isLoadingTabChange, setIsLoadingTabChange] = useState<boolean>(false);

  const {
    data: filteredTracksFirstLoad,
    isLoading,
    error,
  } = useQuery([getFilteredTracks.QUERY_KEY, { userId: userInfo?.id }], () => getFilteredTracks(tabType), {
    cacheTime: 0,
  });

  useEffect(() => {
    if (filteredTracksFirstLoad) {
      setFilteredTracks(filteredTracksFirstLoad);
    }
  }, [filteredTracksFirstLoad]);

  useEffect(() => {
    if (userInfo) {
      setUserId(userInfo.id);
    }
  }, [userInfo]);

  const mappedMenuItems = [
    { label: 'Recommended Tracks', value: MenuItemsTabIds.RECOMMENDED },
    { label: 'In Progress Tracks', value: MenuItemsTabIds.IN_PROGRESS },
    { label: 'Completed Tracks', value: MenuItemsTabIds.COMPLETED },
  ];

  const handleSecondaryMenuClick = async (type: any) => {
    setIsLoadingTabChange(true);
    setTabType(type.value);
    try {
      const response = await getFilteredTracks(type.value);
      setFilteredTracks(response);
    } catch (error) {
      Modal.error({ title: 'Change Tab', content: error.message });
    }
    setIsLoadingTabChange(false);
  };

  const generateBreadcrumb = (name?: string) => {
    return [
      <Link key="1" to="/myhsf/dashboard">
        {name}
      </Link>,
      `${programShortName} University`,
    ];
  };

  const handleLangChange = async (value: string) => {
    try {
      setIsLoadingTabChange(true);
      setPreferredCourseLanguages(userId, { preferredCourseLanguages: value?.split(' ') });
      const response = await getFilteredTracks(tabType);
      setFilteredTracks(response);
    } catch (error) {
      Modal.error({ title: 'Change Language', content: error.message });
    } finally {
      setIsLoadingTabChange(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <StyledContainerTracks>
      <StyledHeaderContainer>
        <NavigationWrapper>
          <Breadcrumb separator={<NavigateNext />}>
            {generateBreadcrumb(`${tenantLabel} Dashboard`).map((item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </NavigationWrapper>

        <Row>
          <Col span={4} offset={10}>
            <LogoContainer>
              <img src={UniversityLogo} alt="University logo" />
            </LogoContainer>
          </Col>
          <Col span={4} offset={3} hidden={true}>
            <StyledLAbel>Language</StyledLAbel>
            <Select defaultValue="English" style={{ width: 200, fontSize: '16px' }} onChange={handleLangChange}>
              <Option value="en">English</Option>
              <Option value="es">Spanish</Option>
              <Option value="en es">English and Spanish</Option>
            </Select>
          </Col>
        </Row>
        <Wrapper>
          Welcome to your online hub for learning! Here you can find the tracks and courses for topics that are relevant
          to you.
        </Wrapper>
        <TabNavigation dataSource={mappedMenuItems} onTabChange={handleSecondaryMenuClick} activeKey={tabType} />
      </StyledHeaderContainer>
      {error && <StyledMessage>{error.message}</StyledMessage>}
      {!error && (
        <Spin spinning={isLoadingTabChange}>
          <TracksList
            dataSource={filteredTracks || []}
            onHandleDetail={(id: string) => navigate(`/myhsf/tracks/${id}`)}
            tracksType={tabType}
          />
        </Spin>
      )}
    </StyledContainerTracks>
  );
}

export default UniversityTracksList;
