import React, { useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Link } from 'gatsby';
import { get } from 'lodash';
import { useMutation } from 'react-query';
import { Spin } from 'antd';
import styled from '@emotion/styled';

import SEO from '../../../components/seo';
import MessageCenterMessageDetails from '../../../components/user/message-center/message-details';
import { useQuery } from '../../../hooks/use-query';
import { getMessageDetails, replyToMessage } from '../../../apis/users/message-center';
import PageHeader from '../../../components/common/page-header';
import { ThreadStatus, IReplyMessageFormData } from '../../../types/models/message-center';
import { Modal } from '../../../components';

type MessageDetailProps = {
  threadId: string;
};

const SpinOverride = styled.div`
  height: 100%;
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
`;

export default function MessageDetails({ threadId }: RouteComponentProps<MessageDetailProps>) {
  const { mutateAsync: sendMessage, isLoading: isReplying } = useMutation<
    void,
    unknown,
    { threadId: string; payload: IReplyMessageFormData }
  >(({ threadId, payload }) => replyToMessage(threadId, payload));

  const { data, isLoading } = useQuery(
    !isReplying && !!threadId && [getMessageDetails.QUERY_KEY, { threadId }],
    ({ queryKey }: { queryKey: [string, { threadId: string }] }) => getMessageDetails(queryKey[1].threadId)
  );

  const handleSend = useCallback(
    async (value: string) => {
      if (!threadId) return false;
      try {
        await sendMessage({ threadId, payload: { body: value } });
        return true;
      } catch (err) {
        if (err instanceof Error) {
          Modal.error({
            title: 'Create Message',
            content: err.message,
          });
        }
      }
      return false;
    },
    [sendMessage, threadId]
  );

  const canReply = get(data, 'status') !== ThreadStatus.CLOSED && !get(data, 'isArchived');

  return (
    <SpinOverride>
      <Spin spinning={isLoading || isReplying}>
        <SEO title="Message Center" />
        <PageHeader
          title="Message Details"
          breadcrumb={[
            <Link key="1" to="/myhsf/message-center">
              Inbox
            </Link>,
            'Message Details',
          ]}
        />
        {data && <MessageCenterMessageDetails details={data} onSend={canReply ? handleSend : undefined} />}
      </Spin>
    </SpinOverride>
  );
}
