import { List, Card, Avatar, Row, Col, Modal, Spin, notification } from 'antd';
import React from 'react';
import { css } from '@emotion/react';
import { useMutation } from 'react-query';
import { navigate } from 'gatsby';

import theme from '../../../../../../theme';
import defaultProfileIcon from '../../../common/default-profile-icon-24.jpg';
import MentorInfoRow from '../../../common/mentor-info-row/mentor-info-row';
import { Button } from '../../../../../../components';
import {
  SelectMentorConfirmContent,
  SelectMentorConfirmTitle,
  SelectMentorCancelText,
  SelectMentorOkText,
} from '../../../common/select-mentor-confirm/select-mentor-confirm';
import * as api from '../../../../../../apis/users/mentorship';

const AvailableMentorCardMetaCss = css({
  paddingBottom: '20px',
  borderBottom: `1px solid ${theme.colorsMediumGrey}`,
  '.ant-card-meta-title': {
    marginBottom: 0,
  },
});

const AvailableMentorCard: React.FC<any> = (props) => {
  const { mutateAsync: selectMentorManually, isLoading: isSelectMentorLoading } = useMutation<
    { isSelected: boolean },
    unknown,
    { mentorId: string }
  >(({ mentorId }) => api.selectMentorManually(mentorId));

  const selectMentorOk = async () => {
    try {
      const response = await selectMentorManually({ mentorId: props.userId });
      if (response.isSelected) {
        props.addMentorIdToSelectedMentors(props.userId);
      }
    } catch (error) {
      notification.error({
        type: 'error',
        closeIcon: true,
        duration: 500,
        placement: 'bottomRight',
        message: 'Error',
        description: error.message,
      });
    }
  };

  const selectMentor = () => {
    Modal.confirm({
      content: <SelectMentorConfirmContent />,
      title: SelectMentorConfirmTitle,
      okText: SelectMentorOkText,
      cancelText: SelectMentorCancelText,
      maskClosable: true,
      onOk: selectMentorOk,
    });
  };

  const learnMore = () => {
    navigate(`/myhsf/my-mentors/${props.userId}`);
  };

  const Actions = () => {
    return (
      <Row type={'flex'} justify={'space-between'} gutter={10}>
        <Col span={12}>
          <Button key={'learn-more'} type={'primary'} onClick={learnMore} block>
            Learn More
          </Button>
        </Col>
        <Col span={12}>
          <Button key={'select-mentor'} type={'primary-blue'} onClick={selectMentor} block>
            {'Select Mentor'}
          </Button>
        </Col>
      </Row>
    );
  };

  const location = [props.city, props.state].filter((v) => v !== null && v !== undefined && v !== '').join(', ');

  const mentorInfo = [
    {
      label: 'Location',
      value: location,
    },
    {
      label: 'Job Type',
      value: props.jobFunction,
    },
    {
      label: 'College Major',
      value: props.primaryMajor,
    },
    {
      label: 'Professional Experience',
      value: props.employmentExperience,
    },
  ];

  const mentorRows = mentorInfo.map((info, i) => <MentorInfoRow key={`mentor-${i}`} {...info} />);
  return (
    <List.Item>
      <Card>
        <Spin spinning={isSelectMentorLoading}>
          <Card.Meta
            avatar={<Avatar size={45} src={props.imageUrl ?? defaultProfileIcon} />}
            title={`${props.firstName} ${props.lastName}`}
            description={props.jobTitle || '-'}
            css={AvailableMentorCardMetaCss}
          />
          <Row style={{ padding: '20px 0 20px 0' }}>{mentorRows}</Row>
          <Actions />
        </Spin>
      </Card>
    </List.Item>
  );
};

export default AvailableMentorCard;
