import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { navigate, Link } from 'gatsby';
import { Spin, Modal } from 'antd';
import styled from '@emotion/styled';

import SEO from '../../../components/seo';
import { useQuery } from '../../../hooks/use-query';
import EventDetails from '../../../components/user/events/event-details';
import {
  IProgram,
  IAgenda,
  IEventAttendee,
  IEventRoom,
  IExpoSchedule,
  ScheduleStatus,
  IAgendaSession,
} from '../../../types/models/program-management';
import { tenantLabel } from '../../../siteContent';
import { FormElementChangeEvent } from '../../../components/forms';
import {
  getEvent,
  getEventRooms,
  getEventDirectory,
  getExpoScheduler,
  updateUserRoomStatus,
  updateExpoScheduleStatus,
} from '../../../apis/users/events';
import { getPrivateAgenda } from '../../../apis/admin/private-event-agendas';
import PageHeader from '../../../components/common/page-header';
import { formatDate, formatDateWithoutTime, getLocalTimezone } from '../../../utils/date';
import { hasUrlProtocol } from '../../../utils/common';
import { getProgramImage, getProgramImageURL } from '../../../apis/users/programs';

const SpinOverride = styled.div`
  height: 100%;
  .ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
`;

type EventDetailsContainerProps = {
  eventId: string;
};

const EventDetailsContainer = ({ eventId = '', location }: RouteComponentProps<EventDetailsContainerProps>) => {
  const isPastEvent = useMemo(() => !!location?.state?.isPastEvent, [location]);
  const [event, setEvent] = useState<IProgram>();
  const [selectedAgendaDate, setSelectedAgendaDate] = useState<string>('');
  const [eventDatesRange, setEventDatesRange] = useState<string[]>();

  const { isLoading, isFetching } = useQuery(
    getEvent.QUERY_KEY,
    () => getEvent(eventId, location?.state?.attendeeStatus),
    {
      onSuccess: async (data) => {
        data.imageUrl = data.imageId ? await getProgramImage(data.id) : null;
        setEvent(data);
      },
    }
  );

  const [agenda, setAgenda] = useState<IAgenda>();
  const [agendaOnDate, setAgendaOnDate] = useState<IAgenda>();
  const { isLoading: isAgendaLoading, isFetching: isAgendaFetching } = useQuery(
    !isPastEvent && getPrivateAgenda.QUERY_KEY,
    () => getPrivateAgenda(eventId),
    {
      onSuccess: async (data) => {
        // Convert the start and end dates to the local time zone
        const sessions = data.sessions.map((session: IAgendaSession) => ({
          ...session,
          startDate: formatDate(session.startDate, 'YYYY-MM-DD h:mm A', getLocalTimezone()?.value),
          endDate: formatDate(session.endDate, 'YYYY-MM-DD h:mm A', getLocalTimezone()?.value),
        }));
        // Build date range from session dates to account for timezones
        if (!isPastEvent && sessions?.length) {
          const datesRangeSet: Set<string> = new Set();
          sessions.map((session: IAgendaSession) => {
            datesRangeSet.add(formatDateWithoutTime(session.startDate));
          });
          const datesRange = Array.from(datesRangeSet);
          setEventDatesRange(datesRange);
          setSelectedAgendaDate(datesRange[0]);
        }
        setAgenda({ ...data, sessions });
      },
    }
  );

  const [schedule, setSchedule] = useState<IExpoSchedule[]>([]);
  const { isLoading: isScheduleLoading, isFetching: isScheduleFetching } = useQuery(
    !isPastEvent && getExpoScheduler.QUERY_KEY,
    () => getExpoScheduler(eventId),
    {
      onSuccess: async (data) => {
        setSchedule(data);
      },
    }
  );

  const [rooms, setRooms] = useState<IEventRoom[]>([]);
  const { isLoading: isRoomsLoading } = useQuery(
    !isPastEvent && getEventRooms.QUERY_KEY,
    () => getEventRooms(eventId),
    {
      onSuccess: async (data) => {
        setRooms(data);
      },
    }
  );

  const [attendees, setAttendees] = useState<IEventAttendee[]>([]);
  const [userImageUrls, setUserImageUrls] = useState<Array<string | null>>([]);
  const { isLoading: isAttendeesLoading, isFetching: isAttendeesFetching } = useQuery(
    !isPastEvent && getEventDirectory.QUERY_KEY,
    () => getEventDirectory(eventId, { limit: 5 }),
    {
      onSuccess: async (data) => {
        setAttendees(data);
      },
    }
  );
  useEffect(() => {
    const ac = new AbortController();

    (async () => {
      const imagesToFetch = attendees.map((item) => getProgramImageURL(item.user.imageId || undefined));
      const fetchedImages = await Promise.all(imagesToFetch);

      setUserImageUrls(fetchedImages);
    })();

    return () => ac.abort();
  }, [attendees]);

  useEffect(() => {
    const sessionsOnDate = agenda?.sessions.filter(
      (session) => formatDateWithoutTime(session.startDate) === selectedAgendaDate
    );
    if (agenda && sessionsOnDate) {
      setAgendaOnDate({ ...agenda, sessions: sessionsOnDate });
    }
  }, [selectedAgendaDate, agenda]);

  const commonBreadcrumb = [
    <Link key="1" to="/myhsf/dashboard">
      {tenantLabel} Dashboard
    </Link>,
    <Link key="2" to="/myhsf/events/registered">
      Events
    </Link>,
  ];

  const navigateToEventDirectory = useCallback(() => {
    navigate(`/myhsf/events/event/${eventId}/directory`);
  }, [eventId]);

  const navigateToAttendeeBio = useCallback(
    (userId?: string) => {
      userId && navigate(`/myhsf/events/event/${eventId}/attendee/${userId}`);
    },
    [eventId]
  );

  const navigateToAgenda = useCallback(
    (agendaId?: string) => {
      agendaId && navigate(`/myhsf/events/event/${eventId}/agenda/${agendaId}`);
    },
    [eventId]
  );

  const handleEnterRoomClick = useCallback(
    (room: IEventRoom, roomIndex: number) => async () => {
      const { id, isVisited } = room;
      let { link } = room;

      if (!hasUrlProtocol(link)) {
        link = `http://${link}`;
      }
      open(link, '_blank');

      if (!isVisited && id) {
        try {
          const data = await updateUserRoomStatus(eventId, id);

          if (data?.statusText) {
            setRooms((prevRooms) => {
              const updatedRooms = [...prevRooms];

              if (updatedRooms[roomIndex]?.id) {
                updatedRooms[roomIndex].isVisited = true;
              }

              return updatedRooms;
            });
          }
        } catch (e) {
          Modal.error({
            title: `Your room attendance status update for ${id} room failed.`,
            content: e.message,
          });
        }
      }
    },
    [eventId]
  );

  const handleSelectAgendaDateChange = useCallback(({ value }: FormElementChangeEvent) => {
    setSelectedAgendaDate(value);
  }, []);

  const handleInterviewStatusUpdate = useCallback(
    (id: string) => async () => {
      try {
        const result = await updateExpoScheduleStatus(eventId, id);
        if (result.status === ScheduleStatus.COMPLETED) {
          setSchedule((prevSchedules) => {
            const schedulesCopy = [...prevSchedules];
            const updatedScheduleIndex = schedulesCopy.findIndex((schedule) => schedule.id === id);
            if (schedulesCopy[updatedScheduleIndex]) {
              schedulesCopy[updatedScheduleIndex].status = ScheduleStatus.COMPLETED;
            }
            return schedulesCopy;
          });
        }
      } catch (e) {
        Modal.error({
          title: `Schedule status update for ${id} failed.`,
          content: e.message,
        });
      }
    },
    [eventId, setSchedule]
  );

  const isReady = !isLoading && !isFetching;
  const isAgendaReady = !isAgendaLoading && !isAgendaFetching;
  const isScheduleReady = !isScheduleLoading && !isScheduleFetching;
  const isAttendeesListReady = !isAttendeesLoading && !isAttendeesFetching;

  return (
    <SpinOverride>
      {!(isReady && event) && <PageHeader title="" noShadow breadcrumb={commonBreadcrumb} />}
      <Spin spinning={!isReady}>
        {isReady && event && (
          <>
            <SEO title={event.name} />
            <EventDetails
              breadcrumb={[...commonBreadcrumb, event.name]}
              isAgendaReady={isAgendaReady}
              isScheduleReady={isScheduleReady}
              isAttendeesListReady={isAttendeesListReady}
              isRoomsReady={!isRoomsLoading}
              event={event}
              agenda={agendaOnDate}
              schedule={schedule}
              rooms={rooms}
              attendees={attendees}
              userImageUrls={userImageUrls}
              selectedAgendaDate={selectedAgendaDate}
              eventDatesRange={eventDatesRange}
              onEventDirectoryClick={navigateToEventDirectory}
              onAttendeeClick={navigateToAttendeeBio}
              onAgendaTileClick={navigateToAgenda}
              onEnterRoomClick={handleEnterRoomClick}
              onSelectAgendaDateChange={handleSelectAgendaDateChange}
              onInterviewStatusChange={handleInterviewStatusUpdate}
            />
          </>
        )}
      </Spin>
    </SpinOverride>
  );
};

export default EventDetailsContainer;
