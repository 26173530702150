import React, { useState, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import { navigate, Link } from 'gatsby';
import { Spin } from 'antd';
import styled from '@emotion/styled';

import SEO from '../../../components/seo';
import { useQuery } from '../../../hooks/use-query';
import AttendeesList from '../../../components/user/events/attendees-list';
import { getProgramImageURL } from '../../../apis/users/programs';
import { tenantLabel } from '../../../siteContent';
import { IEventAttendee } from '../../../types/models/program-management';
import { getEvent, getEventDirectory } from '../../../apis/users/events';

const SpinOverride = styled.div`
  height: 100%;
  .ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
  .ant-breadcrumb .ant-spin-nested-loading,
  .ant-breadcrumb .ant-spin-container {
    display: inline-block;
  }
`;

type EventDirectoryProps = {
  eventId: string;
};

const EventDirectoryContainer = ({ eventId = '' }: RouteComponentProps<EventDirectoryProps>) => {
  const [attendees, setAttendees] = useState<IEventAttendee[]>([]);
  const { isLoading, isFetching, data: event } = useQuery(getEvent.QUERY_KEY, () => getEvent(eventId));

  const limit = !attendees.length ? 10 : undefined;
  const { isLoading: isAttendeesLoading, isFetching: isAttendeesFetching } = useQuery(
    [getEventDirectory.QUERY_KEY, { limit }],
    ({ queryKey }: any) => getEventDirectory(eventId, queryKey[1]),
    {
      onSuccess: async (data) => {
        for (const item of data) {
          item.user.imageUrl = await getProgramImageURL(item.user.imageId);
        }
        setAttendees(data);
      },
    }
  );

  const navigateToAttendeeBio = useCallback(
    (userId?: string) => {
      userId && navigate(`/myhsf/events/event/${eventId}/attendee/${userId}`);
    },
    [eventId]
  );

  const isReady = !isLoading && !isFetching && !isAttendeesLoading && !isAttendeesFetching;

  return (
    <SpinOverride>
      <SEO title="Event directory" />
      <Spin spinning={!isReady && !attendees.length}>
        <AttendeesList
          attendees={attendees}
          isReady={isReady}
          breadcrumb={[
            <Link key="1" to="/myhsf/dashboard">
              {tenantLabel} Dashboard
            </Link>,
            <Link key="2" to="/myhsf/events/registered">
              Events
            </Link>,
            <Spin key="3" spinning={isLoading}>
              <Link to={`/myhsf/events/event/${eventId}`}>{event?.name || ''}</Link>
            </Spin>,
            'Event Directory',
          ]}
          onAttendeeClick={navigateToAttendeeBio}
        />
      </Spin>
    </SpinOverride>
  );
};

export default EventDirectoryContainer;
