import React, { useState } from 'react';
import { Result } from 'antd';
import { PeopleAlt } from '@material-ui/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Button } from '../../../../components';
import { RequestCoach } from '../common/request-coach/request-coach';

const resultCss = css({
  padding: '20vh 32px',
  '.ant-result-icon': {
    marginBottom: 0,
  },
  '.ant-result-title': {
    lineHeight: 1.2,
    marginBottom: '2%',
  },
  '.ant-result-subtitle': {
    color: 'black',
  },
  '.ant-result-extra': {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  '.ant-result-content': {
    padding: 0,
    marginTop: 0,
  },
});

const StyledPeopleAlt = styled(PeopleAlt)`
  font-size: 75px !important;
`;

export const NoCoaches: React.FC<any> = props => {
  const [requestCoachVisible, setRequestCoachVisible] = useState(false);

  const afterSuccessMatch = async () => {
    await props.afterRequestCoach();
  };

  const handleRequestCoachOnCancel = () => {
    setRequestCoachVisible(false);
  };

  const handleRequestCoach = () => {
    setRequestCoachVisible(true);
  };

  const onSelectNewTopic = () => {
    handleRequestCoach();
  };

  if (!props.active) {
    return <Result css={resultCss} icon={<StyledPeopleAlt />} title="You have no Past Coaches yet" />;
  }

  return (
    <Result
      css={resultCss}
      icon={<StyledPeopleAlt />}
      title="You have no Active Coach yet"
      extra={
        <Button type={'primary-blue'} onClick={handleRequestCoach}>
          Request Coach
        </Button>
      }
    >
      <RequestCoach
        isVisible={requestCoachVisible}
        afterSuccessMatch={afterSuccessMatch}
        onCancel={handleRequestCoachOnCancel}
        onSelectNewTopic={onSelectNewTopic}
      />
    </Result>
  );
};
