import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Link } from 'gatsby';
import { Spin } from 'antd';
import styled from '@emotion/styled';

import SEO from '../../../components/seo';
import { useQuery } from '../../../hooks/use-query';
import EventAgendaDetail from '../../../components/user/events/event-agenda-detail';
import { IAgendaSession } from '../../../types/models/program-management';
import { getPrivateAgenda } from '../../../apis/admin/private-event-agendas';
import { getEvent } from '../../../apis/users/events';
import PageHeader from '../../../components/common/page-header';
import { getDocument } from '../../../apis/users/documents';
import { tenantLabel } from '../../../siteContent';
import { Modal } from '../../../components';
import { getProgramImageURL } from '../../../apis/users/programs';

const SpinOverride = styled.div`
  height: 100%;
  .ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
  .ant-breadcrumb .ant-spin-nested-loading,
  .ant-breadcrumb .ant-spin-container {
    display: inline-block;
  }
`;

type MyEventsProps = {
  eventId: string;
  agendaId: string;
};

const AgendaDetail = ({ eventId = '', agendaId = '' }: RouteComponentProps<MyEventsProps>) => {
  const [agenda, setAgenda] = useState<IAgendaSession>();
  const [participantImageUrls, setParticipantImageUrls] = useState<Array<string | null>>([]);

  const { isLoading, isFetching } = useQuery(getPrivateAgenda.QUERY_KEY, () => getPrivateAgenda(eventId), {
    onSuccess: async data => {
      const newAgenda = data.sessions.filter(agenda => agenda.id === agendaId)[0];
      setAgenda(newAgenda);
    },
  });

  useEffect(() => {
    const ac = new AbortController();

    (async () => {
      const imagesToFetch = agenda?.participants?.map(item => getProgramImageURL(item.imageId || undefined));
      const fetchedImages = await Promise.all(imagesToFetch || []);

      setParticipantImageUrls(fetchedImages);
    })();

    return () => ac.abort();
  }, [agenda]);

  const { isLoading: isEventLoading, data: event } = useQuery(getEvent.QUERY_KEY, () => getEvent(eventId));

  const onDownloadClickHandler = useCallback(async (id: string, fileName: string) => {
    try {
      const file: File = await getDocument(id, true);
      const objectUrl = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = objectUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      Modal.error({
        title: `Document cannot be downloaded.`,
        content: e.message,
      });
    }
  }, []);

  const commonBreadcrumb = [
    <Link key="1" to="/myhsf/dashboard">
      {tenantLabel} Dashboard
    </Link>,
    <Link key="2" to="/myhsf/events/registered">
      Events
    </Link>,
  ];

  const isReady = !isLoading && !isFetching;

  return (
    <SpinOverride>
      <SEO title="Event agenda" />
      {!(isReady && agenda) && <PageHeader title="" noShadow breadcrumb={commonBreadcrumb} />}
      <Spin spinning={!isReady}>
        {isReady && agenda && (
          <EventAgendaDetail
            breadcrumb={[
              ...commonBreadcrumb,
              <Spin key="3" spinning={isEventLoading}>
                <Link to={`/myhsf/events/event/${eventId}`}>{event?.name}</Link>
              </Spin>,
              agenda.name,
            ]}
            agenda={agenda}
            participantImageUrls={participantImageUrls}
            onDownload={onDownloadClickHandler}
            eventId={eventId}
          />
        )}
      </Spin>
    </SpinOverride>
  );
};

export default AgendaDetail;
