import React, { memo } from 'react';
import { Row, Col, Spin } from 'antd';
import styled from '@emotion/styled';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { formatUniversityName } from '../../../utils/form';
import theme from '../../../theme';
import PageHeader, { PageHeaderProps } from '../../common/page-header';
import UserContent from '../../common/user-content';
import { IEventAttendee, StageType } from '../../../types/models/program-management';

type AttendeesListProps = {
  attendees: IEventAttendee[];
  isReady: boolean;
  breadcrumb?: PageHeaderProps['breadcrumb'];
  onAttendeeClick: (attendeeId: string) => void;
};

const StyledUserContent = styled(UserContent)`
  background-color: transparent;
  padding: 0;
  color: #000;
`;

const ListContainer = styled(Row)`
  background-color: #fff;
  padding: 0 24px;
`;

const SectionRow = styled(Row)`
  margin: 14px 0 12px;
`;

const AvatarContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: 0 24px 12px 5px;
  overflow: hidden;
  border-radius: 50%;
`;

const DefaultAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #d8d8d8;
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
`;

const Name = styled.div`
  color: ${theme.colorsBlack};
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 3px;
  cursor: pointer;
`;

const Text = styled.div`
  color: ${theme.colorsBlack};
  font-size: 14px;
`;

const StyledSpin = styled(Spin)`
  padding-bottom: 20px;
`;

const StyledRightIcon = styled(ChevronRightIcon)`
  margin-right: 8px;
  cursor: pointer;
`;

const StyledHr = styled.hr`
  margin: 12px 0;
  border: 0;
  height: 1px;
  background-color: ${theme.colorsHr};
`;

const AttendeesList = memo(function AttendeesList({
  attendees,
  isReady,
  breadcrumb,
  onAttendeeClick,
}: AttendeesListProps) {
  return (
    <>
      <PageHeader title="Event Directory" noShadow breadcrumb={breadcrumb} />
      <StyledUserContent mobileFooterMargin={77}>
        <ListContainer>
          {!!attendees?.length &&
            attendees.map((attendee, index) => {
              const { user, stageType, currentUniversityName, eventRoles } = attendee;
              const { id, imageUrl, stageInvitationUserId, firstName, lastName } = user;
              const isLast = index === attendees.length - 1;

              return (
                <Row key={id}>
                  <SectionRow key={id} justify="space-between" align="middle">
                    <Col xs={18}>
                      <Row>
                        <AvatarContainer xs={2}>
                          {imageUrl ? (
                            <Avatar src={imageUrl} />
                          ) : (
                            <DefaultAvatar>
                              {firstName?.charAt(0)}
                              {lastName?.charAt(0)}
                            </DefaultAvatar>
                          )}
                        </AvatarContainer>
                        <Col xs={21}>
                          <Name onClick={() => onAttendeeClick(stageInvitationUserId)}>
                            {firstName} {lastName}
                          </Name>
                          {stageType === StageType.PRIVATE_EVENT_APPLICANTS && currentUniversityName && (
                            <Text>{formatUniversityName(currentUniversityName)}</Text>
                          )}
                          {stageType === StageType.PRIVATE_EVENT_NON_APPLICANTS && (
                            <Text>{eventRoles?.join(', ')}</Text>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <StyledRightIcon onClick={() => onAttendeeClick(stageInvitationUserId)} />
                    </Col>
                  </SectionRow>
                  {!isLast && <StyledHr />}
                </Row>
              );
            })}
          {!isReady && !!attendees.length && <StyledSpin spinning />}
        </ListContainer>
      </StyledUserContent>
    </>
  );
});

export default AttendeesList;
