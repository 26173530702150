import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';
import { Row, Col, List, Pagination, Spin } from 'antd';
import { Link, navigate } from 'gatsby';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Empty } from 'antd5';

import CareerFilter from './career-filters';
import CareerCard from './career-card';

import { ITrack } from '../../../types/models/hsf-university';
import { getUser } from '../../../state/selectors/auth';
import { getTracks } from '../../../apis/users/hsf-university';
import { StyledButton } from '../../../components/common/header-styles';
import PageHeader from '../../../components/common/page-header';
import TabNavigation, { ITabNavigationDataSourceItem } from '../../../components/common/tab-navigation';
import { FormElementChangeEvent } from '../../../components/forms';
import { tenantLabel } from '../../../siteContent';
import theme from '../../../theme';
import {
  getCareers,
  selectCareer,
  unselectCareer,
  getMajorCategory,
  getMajorCategories,
} from '../../../apis/users/survey';
import { useQuery } from '../../../hooks/use-query';
import { Career } from '../../../types/models/discernment-survey';
import { IUser } from '../../../types/models/auth';
import { getTrackListCta } from '../university-track/dashboard-tracks-tile';

const BaseContainer = styled(Row)`
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
`;

const StyledList = styled(List)`
  & > div > div > div {
    display: flex;
    flex-wrap: wrap;
  }
`;

const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${theme.screenXl};
`;

const StyledListContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: ${theme.screenXl};
`;

const TileContainer = styled(BaseContainer)`
  background-color: ${theme.colorsLightestGrey};
`;

const Content = styled(Row)`
  width: 100%;
  margin: 0 auto;
  max-width: ${theme.screenXl};
`;

const ListToolbarRow = styled(Row)`
  margin: 20px 0px 20px 0px;
`;

const PAGE_SIZE_LIMIT = 9;

enum CareerType {
  SELECTED = 'selected',
  RECOMMENDED = 'recommended',
  ALL = 'all',
}

const CareerTypeLabel = new Map<CareerType, string>([
  [CareerType.SELECTED, 'Selected In-Demand Careers'],
  [CareerType.RECOMMENDED, 'Recommended In-Demand Careers'],
  [CareerType.ALL, 'All In-Demand Careers'],
]);

type QueryParams = {
  type: CareerType;
  offset: number;
  limit: number;
  tabs: Record<
    string,
    {
      filter: string;
      searchText: string;
    }
  >;
};

const initCount = {
  [CareerType.SELECTED]: 0,
  [CareerType.RECOMMENDED]: 0,
  [CareerType.ALL]: 0,
};

const DiscernmentSurveyResults = (_: RouteComponentProps) => {
  const userInfo: IUser | null = useSelector(getUser);
  const [selectedTab, setSelectedTab] = useState<ITabNavigationDataSourceItem>({
    value: CareerType.RECOMMENDED,
    label: CareerTypeLabel.get(CareerType.RECOMMENDED),
  });
  const [totalCount, setTotalCount] = useState<Record<CareerType, number>>(initCount);
  const [offset, setOffset] = useState<number>(0);
  const [tabPagination, setTabPagination] = useState<number>(1);
  const [filters, setFilters] = useState<any>({});
  const [careers, setCareers] = useState<Career[]>([]);
  const [params, setParams] = useState<QueryParams>({
    type: selectedTab.value,
    offset,
    limit: PAGE_SIZE_LIMIT,
    tabs: {},
  });

  const [showTracksButton, setShowTracksButton] = useState<boolean>();
  const [tracksButtonCTA, setTracksButtonCTA] = useState<string>('');

  const { data: tracksData, refetch: refetchTracks } = useQuery([getTracks.QUERY_KEY, { userId: userInfo?.id }], () =>
    getTracks()
  );

  const { refetch, isLoading, isFetching } = useQuery(
    [getCareers.QUERY_KEY, params],
    () =>
      getCareers({
        ...params.tabs[selectedTab.value],
        type: params.type,
        offset: params.offset,
        limit: params.limit,
      }),
    {
      onSuccess: ({ careers, count }) => {
        setCareers(careers);
        setTotalCount((prevCount) => ({ ...prevCount, [selectedTab.value]: count }));
      },
      onError: () => {
        setCareers([]);
        setTotalCount(initCount);
      },
    }
  );
  useEffect(() => {
    const trackData = tracksData as ITrack;
    if (trackData?.tracks?.length) {
      const ctaPath = getTrackListCta({ dashboardType: trackData?.type || '' });
      setTracksButtonCTA(ctaPath);
      setShowTracksButton(true);
    } else {
      setShowTracksButton(false);
    }
  }, [tracksData]);

  useEffect(() => {
    // get selected careers and count set tab count
    getCareers({
      type: CareerType.SELECTED,
      offset: params.offset,
      limit: 1,
    })
      .then(({ count }) => {
        if (count) {
          setTotalCount((prevCount) => ({ ...prevCount, [CareerType.SELECTED]: count }));
        }
      })
      .catch(() => {
        console.error('Error fetching selected careers count');
      });
  }, []);

  const { data: majors, isLoading: loadingMajors } = useQuery(getMajorCategories.QUERY_KEY, getMajorCategories);

  const { mutateAsync: sendSelect, isLoading: isSendingSelect } = useMutation<string, unknown, any>((id: string) =>
    selectCareer(id)
  );

  const { mutateAsync: sendUnSelect, isLoading: isSendingUnSelect } = useMutation<string, unknown, any>((id: string) =>
    unselectCareer(id)
  );

  const { mutateAsync: getCareerMajorCategories, isLoading: isLoadingData } = useMutation<string, unknown, any>(
    (id: string) => getMajorCategory(id)
  );

  const handleSelect = useCallback(
    async (id: string) => {
      await sendSelect(id);
      refetch();
      refetchTracks();
      setTotalCount((prevCount) => ({ ...prevCount, [CareerType.SELECTED]: prevCount[CareerType.SELECTED] + 1 }));
    },
    [sendSelect, refetch, refetchTracks]
  );

  const handleUnSelect = useCallback(
    async (id: string) => {
      await sendUnSelect(id);
      refetch();
      setTotalCount((prevCount) => ({ ...prevCount, [CareerType.SELECTED]: prevCount[CareerType.SELECTED] - 1 }));
    },
    [sendUnSelect, refetch]
  );

  const description = useMemo(
    () => (
      <Row key="1" align="middle" justify="end">
        <Col xs={24}></Col>
      </Row>
    ),
    []
  );

  const breadcrumb = useMemo(
    () => [
      <Link key="1" to={`/myhsf/dashboard`}>
        {tenantLabel} Dashboard
      </Link>,
      'Discernment Survey Results',
    ],
    []
  );

  const tabDataSource: ITabNavigationDataSourceItem[] = useMemo(
    () => [
      {
        label: CareerTypeLabel.get(CareerType.RECOMMENDED),
        value: CareerType.RECOMMENDED,
      },
      {
        label: CareerTypeLabel.get(CareerType.SELECTED),
        value: CareerType.SELECTED,
        count: totalCount[CareerType.SELECTED],
      },
      {
        label: CareerTypeLabel.get(CareerType.ALL),
        value: CareerType.ALL,
      },
    ],
    [totalCount]
  );

  const getFilterParams = (filters: any) => {
    const filter: any[] = [];
    //search by major
    if (filters.major && filters.major.length) {
      const majors = {
        field: 'majors.id',
        operator: 'in',
        value: filters.major,
      };
      filter.push(majors);
    }
    return {
      filter: JSON.stringify(filter),
      search: filters.searchText,
    };
  };

  const handleTabChange = useCallback(
    (item: ITabNavigationDataSourceItem) => {
      setSelectedTab(item);
      setTabPagination(1);
      setOffset(0);
      setParams((prev: any) => ({
        ...prev,
        offset: 0,
        limit: PAGE_SIZE_LIMIT,
        type: item.value,
        tabs: {
          [item.value]: {
            ...getFilterParams(filters[item.value] ?? {}),
          },
        },
      }));
    },
    [filters]
  );

  const renderTabs = useCallback(() => {
    return <TabNavigation dataSource={tabDataSource} activeKey={selectedTab.value} onTabChange={handleTabChange} />;
  }, [handleTabChange, tabDataSource, selectedTab]);

  const onChangePagination = useCallback(async (page: number) => {
    const offsetLocal = page === 1 ? 0 : (page - 1) * PAGE_SIZE_LIMIT;
    setOffset(offsetLocal);
    setParams((prevState: any) => ({ ...prevState, offset: offsetLocal }));
    setTabPagination(page);
  }, []);

  const onTitleButtonClick = useCallback(async () => {
    navigate(tracksButtonCTA);
  }, [tracksButtonCTA]);

  const renderTitleButton = useCallback(() => {
    return showTracksButton ? (
      <StyledButton type="primary-blue" onClick={onTitleButtonClick}>
        Start Track
        <ArrowRightOutlined />
      </StyledButton>
    ) : null;
  }, [onTitleButtonClick, showTracksButton]);

  const handleOnChange = useCallback(
    ({ name, value }: FormElementChangeEvent) => {
      if (name) {
        setFilters((prevState: any) => ({
          ...prevState,
          [selectedTab.value]: {
            ...prevState[selectedTab.value],
            [name]: value,
          },
        }));
      }
    },
    [selectedTab.value]
  );

  const handleOnBlur = useCallback(async () => {
    console.log('blur');
  }, []);

  const handleSearch = useCallback(
    (filters) => {
      //update params
      setParams((prev) => ({
        ...prev,
        offset: 0,
        tabs: {
          [selectedTab.value]: {
            ...prev.tabs[selectedTab.value],
            ...getFilterParams(filters),
          },
        },
      }));
      setTabPagination(1);
    },
    [selectedTab.value]
  );

  const renderItems = useCallback(() => {
    return (
      <StyledListContainer>
        <ListToolbarRow justify="space-between" align="middle">
          <h1>{selectedTab.label}</h1>
          {totalCount[params.type] > PAGE_SIZE_LIMIT ? (
            <Pagination
              simple
              current={tabPagination}
              defaultCurrent={1}
              pageSize={PAGE_SIZE_LIMIT}
              total={totalCount[params.type]}
              onChange={onChangePagination}
            />
          ) : null}
        </ListToolbarRow>

        <StyledListContent>
          <StyledList
            itemLayout="horizontal"
            grid={{
              gutter: 24,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 3,
            }}
            size="large"
            dataSource={careers}
            loading={isFetching}
            locale={{ emptyText: <Empty description="No Match Found"></Empty> }}
            renderItem={(item: any) => (
              <List.Item key={`list-item-${item.id}`}>
                <CareerCard
                  key={`card-${item.id}`}
                  item={item}
                  getMajorCategories={getCareerMajorCategories}
                  select={() => {
                    handleSelect(item.id);
                  }}
                  unselect={() => {
                    handleUnSelect(item.id);
                  }}
                />
              </List.Item>
            )}
          ></StyledList>
        </StyledListContent>
        {totalCount[params.type] > PAGE_SIZE_LIMIT ? (
          <ListToolbarRow justify="end" align="middle">
            <Pagination
              simple
              current={tabPagination}
              defaultCurrent={1}
              pageSize={PAGE_SIZE_LIMIT}
              total={totalCount[params.type]}
              onChange={onChangePagination}
            />
          </ListToolbarRow>
        ) : null}
      </StyledListContainer>
    );
  }, [
    selectedTab.label,
    totalCount,
    params.type,
    tabPagination,
    onChangePagination,
    careers,
    getCareerMajorCategories,
    handleSelect,
    handleUnSelect,
    isFetching,
  ]);

  useEffect(() => {
    refetch();
  }, [offset, selectedTab, params, refetch]);

  return (
    <div style={{ minWidth: '320px' }}>
      <Spin spinning={isLoading || isSendingSelect || isSendingUnSelect || isLoadingData || loadingMajors}>
        <PageHeader
          title="Discernment Survey Results"
          description={description}
          breadcrumb={breadcrumb}
          extra={renderTabs()}
          titleButton={renderTitleButton()}
        />
        <CareerFilter
          filters={filters[selectedTab.value] ?? {}}
          majors={majors}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleSearch={handleSearch}
        />
        <TileContainer>
          <Content>{renderItems()}</Content>
        </TileContainer>
      </Spin>
    </div>
  );
};

export default DiscernmentSurveyResults;
