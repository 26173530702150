import React from 'react';
import styled from '@emotion/styled';
import { Avatar, Col, Row } from 'antd';

import theme from '../../../theme';
import { getInitials } from '../../../utils/users';
import { useResponsive } from '../../../hooks/use-responsive';

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  display: contents;
`;

const UserBioSection = styled.div`
  width: 100%;
  padding: 15px 0 30px 0;
  background: ${theme.colorsWhite};
`;

const UserBioContainer = styled.div`
  max-width: ${theme.screenXl};
  margin: 0 auto;
  @media (max-width: ${theme.screenLgMax}) {
    padding: 0 24px;
    margin: 0;
    max-width: none;
  }
`;

const centeredColCss = `
  display: flex;
  align-items: end;
  padding-top: 10px;
  justify-content: center;
`;

const UserAvatar = styled(Avatar)`
  color: #f56a00;
  background-color: #fde3cf;
  margin: 30px 20px;
`;

const headerRowCss = `
  flex-direction: column;
  padding-bottom: 15px;
`;

const mentorInfoRowCss = {
  color: 'black',
  fontSize: 'larger',
  flexFlow: 'row nowrap',
  margin: '1%',
};

interface UserBioRowProps {
  label: string;
  value: string | null | undefined;
  customCss?: any;
}

const StyledRow = styled(Row);
const StyledCol = styled(Col);

const UserBioInfoRow = ({ label, value, customCss }: UserBioRowProps) => {
  const MentorInfoRow = StyledRow(customCss ?? mentorInfoRowCss);

  return (
    <MentorInfoRow>
      {value && (
        <React.Fragment>
          <Label>{`${label}:`}&nbsp;</Label>
          {value}
        </React.Fragment>
      )}
    </MentorInfoRow>
  );
};

interface UserBioHeaderProps {
  image?: string;
  title: string;
  subtitle?: string;
  button?: React.ReactNode;
  userInfoRows: Array<{ label: string; value: string | undefined | null; customCss?: any }>;
}

const UserBioHeader: React.FC<UserBioHeaderProps> = (props) => {
  const [firstName, lastName] = props.title.split(' ');
  const { xl } = useResponsive();
  const isMobile = !xl;

  const rows = props.userInfoRows.map((info, index) => {
    return <UserBioInfoRow key={index} label={info.label} value={info.value} customCss={info.customCss} />;
  });

  const CenteredCol = StyledCol(centeredColCss);
  const HeaderRow = StyledRow(headerRowCss);

  return (
    <UserBioSection>
      <UserBioContainer>
        <Row gutter={15}>
          <CenteredCol span={isMobile ? 24 : 4}>
            <UserAvatar size={125} src={props.image}>
              {getInitials({ firstName, lastName })}
            </UserAvatar>
          </CenteredCol>
          <Col span={isMobile ? 24 : 16}>
            <HeaderRow>
              <h1 style={{ fontWeight: 'bold' }}>{props.title}</h1>
              <h2>{props.subtitle}</h2>
            </HeaderRow>
            <Row gutter={25}>
              <Col span={isMobile ? 24 : 12}>{rows.slice(0, 3)}</Col>
              {rows.length > 3 && <Col span={isMobile ? 24 : 12}>{rows.slice(3)}</Col>}
            </Row>
          </Col>
          <CenteredCol span={isMobile ? 24 : 4}>{props.button}</CenteredCol>
        </Row>
      </UserBioContainer>
    </UserBioSection>
  );
};

export default UserBioHeader;
