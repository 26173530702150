import { RouteComponentProps } from '@reach/router';
import React, { useCallback } from 'react';
import { navigate, Link } from 'gatsby';
import { useMutation } from 'react-query';
import { Spin } from 'antd';
import styled from '@emotion/styled';

import SEO from '../../../components/seo';
import MessageCenterCreateMessage from '../../../components/user/message-center/create-message';
import { ICreateMessageFormData } from '../../../types/models/message-center';
import { createMessage } from '../../../apis/users/message-center';
import { Modal } from '../../../components';

const SpinOverride = styled.div`
  height: 100%;
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
`;

const CreateMessage = (_: RouteComponentProps) => {
  const { mutateAsync: sendMessage, isLoading } = useMutation<void, unknown, ICreateMessageFormData>(
    (formData: ICreateMessageFormData) => createMessage(formData)
  );
  const dashboardUrl = '/myhsf/message-center';
  const navigateToDashboard = () => navigate(dashboardUrl);
  const handleSend = useCallback(
    async (formData: ICreateMessageFormData) => {
      try {
        await sendMessage(formData);
        Modal.info({
          title: 'Create Message',
          content: 'Message has been successfully sent.',
          onOk: navigateToDashboard,
        });
      } catch (err) {
        Modal.error({
          title: 'Create Message',
          content: err.message,
        });
      }
    },
    [sendMessage]
  );
  const handleCancel = navigateToDashboard;

  return (
    <SpinOverride>
      <SEO title="Message Center" />
      <Spin spinning={isLoading}>
        <MessageCenterCreateMessage
          title="Create Message"
          breadcrumb={[
            <Link key="1" to={dashboardUrl}>
              Inbox
            </Link>,
            'Create Message',
          ]}
          onSend={handleSend}
          onCancel={handleCancel}
        />
      </Spin>
    </SpinOverride>
  );
};

export default CreateMessage;
