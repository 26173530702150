import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { Link } from 'gatsby';
import { Spin } from 'antd';
import styled from '@emotion/styled';

import SEO from '../../../components/seo';
import EventsMyEvents from '../../../components/user/events/my-events';
import { tenantLabel } from '../../../siteContent';
import { TabItem } from '../../../types/models/events';

const SpinOverride = styled.div`
  height: 100%;
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
`;

type MyEventsProps = {
  activeTab: TabItem;
};

const MyEvents = ({ activeTab = TabItem.MY_REGISTERED_EVENTS }: RouteComponentProps<MyEventsProps>) => {
  return (
    <SpinOverride>
      <SEO title="Events" />
      <Spin spinning={false}>
        <EventsMyEvents
          title="My Events"
          breadcrumb={[
            <Link key="1" to="/myhsf/dashboard">
              {tenantLabel} Dashboard
            </Link>,
            'Events',
          ]}
          activeTab={activeTab}
        />
      </Spin>
    </SpinOverride>
  );
};

export default MyEvents;
