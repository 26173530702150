import React, { useState, useCallback, useEffect } from 'react';
import { Select, Spin } from 'antd';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';

import { getSchoolList } from '../../../apis/public/master-tables';

const { Option } = Select;

const INITIAL_FETCH_VALUE = 'ab';

const SchoolRemoteSelect = ({ schoolValue, handleSchoolFilterOnChange }: any) => {
  const [value, setValue] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [fetchValue, setFetchValue] = useState(INITIAL_FETCH_VALUE);

  useEffect(() => {
    if (!schoolValue.length) {
      setValue([]);
    }
  }, [schoolValue]);

  const { isFetching, refetch } = useQuery(getSchoolList.QUERY_KEY_FILTERED, () => getSchoolList(fetchValue), {
    onSuccess: data => {
      setSchoolData(
        data.map((item: any) => ({
          value: item.displayText?.split('|')[0],
          id: item.id,
        }))
      );
    },
  });

  const debounceFetchSchool = useCallback(
    debounce((value: any) => {
      setFetchValue(value);
      refetch();
    }, 600),
    [fetchValue]
  );

  const fetchSchool = (value: string) => {
    if (!value || value.length < 3) {
      return;
    }

    setSchoolData([]);
    debounceFetchSchool(value);
  };

  const handleChange = (value: any) => {
    setValue(value);
    handleSchoolFilterOnChange(value?.map((item: any) => item.key));
  };

  const onFocusHandle = () => {
    setSchoolData([]);
    setFetchValue(INITIAL_FETCH_VALUE);
    refetch();
  };

  const CurrentData: JSX.Element[] = schoolData?.map((data: any) => (
    <Option key={data.id} value={data.id}>
      {data.value}
    </Option>
  ));

  return (
    <Select
      mode="multiple"
      labelInValue
      value={value}
      size="large"
      maxTagCount={1}
      maxTagTextLength={10}
      placeholder="Select one or more"
      notFoundContent={isFetching ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={fetchSchool}
      onChange={handleChange}
      onFocus={onFocusHandle}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
    >
      {CurrentData}
    </Select>
  );
};

export default SchoolRemoteSelect;
